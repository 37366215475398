import Button from "@mui/material/Button";
import Box, { BoxProps } from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";

import { CustomButtonStyleProps } from "./CustomButton.types";

import { defaultBorderRadius } from "../../constants";
import theme from "theme/muiTheme";
import { rem } from "theme/muiSizes";

export const CustomButtonPrimary = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "customButtonModel" &&
    props !== "customButtonVariant" &&
    props !== "customButtonSize",
})<CustomButtonStyleProps>(({ variant, customButtonSize }) => ({
  borderRadius: defaultBorderRadius,
  minWidth: rem(67),
  minHeight: rem(42),
  padding: `${rem(5)} ${rem(12)}`,
  ...(customButtonSize === "large" && {
    minWidth: rem(82),
    minHeight: rem(50),
  }),
  ...(customButtonSize === "icon-large" && {
    minWidth: rem(50),
    minHeight: rem(50),
    padding: `${rem(5)} ${rem(9)}`,
  }),
  ...(variant === "contained" && {
    "&.MuiButton-contained:hover": {
      background: theme.palette.primary.light,
      boxShadow: "none",
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
      background: theme.palette.primary.main,
    },
    // "&.MuiButton-contained:focus": {
    //   border: "none",
    //   outline: "none",
    //   position: "relative",
    //   background: alpha(theme.palette.primary.light, 0.8),
    // },
  }),
  ...(variant === "outlined" && {
    backgroundColor: "transparent",
    "&.MuiButton-outlined": {
      border: `${rem(1)} solid ${theme.palette.primary.main}`,
      boxShadow: "none",
      background: "transparent",
      borderRadius: rem(4),
      ".MuiBox-root": {
        background: theme.palette.primary.main,
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
    "&.MuiButton-outlined:hover": {
      border: `${rem(1)} solid ${alpha(theme.palette.primary.main, 0.5)}`,
      backgroundColor: "transparent",
      ".MuiBox-root": {
        background: alpha(theme.palette.primary.main, 0.5),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
    "&.MuiButton-outlined:focus": {
      outlineWidth: rem(2),
      outlineStyle: "solid",
      outlineColor: alpha(theme.palette.primary.main, 0.8),
      border: "none",
      ".MuiBox-root": {
        background: alpha(theme.palette.primary.main, 0.8),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
  }),
}));

export const CustomButtonSecondary = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "customButtonModel" &&
    props !== "customButtonVariant" &&
    props !== "customButtonSize",
})<CustomButtonStyleProps>(({ variant, customButtonSize }) => ({
  borderRadius: defaultBorderRadius,
  minWidth: rem(67),
  minHeight: rem(42),
  padding: `${rem(5)} ${rem(12)}`,
  ...(customButtonSize === "large" && {
    minWidth: rem(82),
    minHeight: rem(50),
  }),
  ...(customButtonSize === "icon-large" && {
    minWidth: rem(50),
    minHeight: rem(50),
    padding: `${rem(5)} ${rem(9)}`,
  }),
  ...(variant === "contained" && {
    "&.MuiButton-contained": {
      boxShadow: "none",
      background: theme.palette.secondary.main,
    },
    "&.MuiButton-contained:hover": {
      background: theme.palette.secondary.light,
      boxShadow: "none",
    },
  }),
  ...(variant === "outlined" && {
    backgroundColor: "transparent",
    "&.MuiButton-outlined": {
      border: `${rem(1)} solid ${theme.palette.secondary.main}`,
      boxShadow: "none",
      background: "transparent",
      borderRadius: rem(4),
      minHeight: rem(42),
      ".MuiBox-root": {
        color: theme.palette.secondary.main,
      },
    },
    "&.MuiButton-outlined:hover": {
      border: `${rem(1)} solid ${theme.palette.secondary.light}`,
      backgroundColor: "transparent",
      ".MuiBox-root": {
        background: theme.palette.secondary.light,
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
  }),
}));

export const CustomButtonDanger = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "customButtonModel" &&
    props !== "customButtonVariant" &&
    props !== "customButtonSize",
})<CustomButtonStyleProps>(({ variant, customButtonSize }) => ({
  borderRadius: defaultBorderRadius,
  minWidth: rem(67),
  minHeight: rem(42),
  padding: `${rem(5)} ${rem(12)}`,
  ...(customButtonSize === "large" && {
    minWidth: rem(82),
    minHeight: rem(50),
  }),
  ...(customButtonSize === "icon-large" && {
    minWidth: rem(50),
    minHeight: rem(50),
    padding: `${rem(5)} ${rem(9)}`,
  }),
  ...(variant === "contained" && {
    "&.MuiButton-contained": {
      boxShadow: "none",
      background: theme.palette.error.main,
      color: theme.palette.text.primary,
    },
    "&.MuiButton-contained:hover": {
      boxShadow: "none",
    },
  }),
  ...(variant === "outlined" && {
    backgroundColor: "transparent",
    "&.MuiButton-outlined": {
      border: `${rem(1)} solid ${theme.palette.error.main}`,
      boxShadow: "none",
      background: "transparent",
      borderRadius: rem(4),
      ".MuiBox-root": {
        color: theme.palette.error.main,
      },
    },
    "&.MuiButton-outlined:hover": {
      border: `${rem(1)} solid ${alpha(theme.palette.error.main, 0.5)}`,
      backgroundColor: "transparent",
      ".MuiBox-root": {
        background: alpha(theme.palette.error.main, 0.5),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
    "&.MuiButton-outlined:focus": {
      outlineWidth: rem(1),
      outlineStyle: "solid",
      outlineColor: alpha(theme.palette.error.main, 0.8),
      border: "none",
      ".MuiBox-root": {
        background: alpha(theme.palette.error.main, 0.8),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
  }),
}));

export const CustomButtonDisabled = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "customButtonModel" &&
    props !== "customButtonVariant" &&
    props !== "customButtonSize",
})<CustomButtonStyleProps>(({ variant, customButtonSize, theme }) => ({
  borderRadius: defaultBorderRadius,
  minWidth: rem(67),
  minHeight: rem(42),
  padding: `${rem(5)} ${rem(12)}`,
  ...(customButtonSize === "large" && {
    minWidth: rem(82),
    minHeight: rem(50),
  }),
  ...(customButtonSize === "icon-large" && {
    minWidth: rem(50),
    minHeight: rem(50),
    padding: `${rem(5)} ${rem(9)}`,
  }),
  ...(variant === "contained" && {
    "&.MuiButton-contained:disabled": {
      backgroundColor: theme.palette.text.disabled,
      cursor: "not-allowed",
      pointerEvents: "auto",
      ".MuiBox-root": {
        color: theme.palette.text.disabled,
      },
    },
  }),
  ...(variant === "outlined" && {
    "&.MuiButton-outlined:disabled": {
      backgroundColor: "transparent",
      border: `${rem(2)} solid ${theme.palette.text.disabled}`,
      cursor: "not-allowed",
      pointerEvents: "auto",
      ".MuiBox-root": {
        color: theme.palette.text.disabled,
      },
    },
  }),
}));

export const CustomButtonSuccess = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "customButtonModel" &&
    props !== "customButtonVariant" &&
    props !== "customButtonSize",
})<CustomButtonStyleProps>(({ variant, customButtonSize }) => ({
  borderRadius: defaultBorderRadius,
  minWidth: rem(67),
  minHeight: rem(42),
  padding: `${rem(5)} ${rem(12)}`,
  ...(customButtonSize === "large" && {
    minWidth: rem(82),
    minHeight: rem(50),
  }),
  ...(customButtonSize === "icon-large" && {
    minWidth: rem(50),
    minHeight: rem(50),
    padding: `${rem(5)} ${rem(9)}`,
  }),
  ...(variant === "contained" && {
    "&.MuiButton-contained": {
      boxShadow: "none",
      background: theme.palette.success.main,
      borderRadius: rem(4),
    },
    "&.MuiButton-contained:hover": {
      backgroundColor: alpha(theme.palette.success.main, 0.5),
      boxShadow: "none",
    },
    "&.MuiButton-contained:focus": {
      background: alpha(theme.palette.success.main, 0.8),
      outlineWidth: rem(5),
      outlineStyle: "solid",
    },
  }),
  ...(variant === "outlined" && {
    backgroundColor: "transparent",
    "&.MuiButton-outlined": {
      border: `${rem(1)} solid ${theme.palette.success.main}`,
      boxShadow: "none",
      background: "transparent",
      borderRadius: rem(4),
      ".MuiBox-root": {
        color: theme.palette.success.main,
      },
    },
    "&.MuiButton-outlined:hover": {
      border: `${rem(1)} solid ${alpha(theme.palette.success.main, 0.5)}`,
      backgroundColor: "transparent",
      ".MuiBox-root": {
        background: alpha(theme.palette.success.main, 0.5),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
    "&.MuiButton-outlined:focus": {
      outlineWidth: rem(2),
      outlineStyle: "solid",
      outlineColor: alpha(theme.palette.success.main, 0.8),
      border: "none",
      ".MuiBox-root": {
        background: alpha(theme.palette.success.main, 0.8),
        WebkitBbackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
      },
    },
  }),
}));

export const CustomButtonContent = styled(Box)<BoxProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.9rem",
  fontFamily: "PathwayExtreme-Medium",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
}));
