import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { vwMobile } from "theme/muiSizes";

export const PagesContainerBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: "40px 24px 70px",
  minHeight: "calc(100vh - 70px)",
  boxSizing: "border-box",
  position: "relative",
  overflow: "hidden",
  zIndex: 0,
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(440)]: {
    padding: `${vwMobile(40)} ${vwMobile(20)} ${vwMobile(70)}`,
    minHeight: `calc(100vh - ${vwMobile(70)})`,
  },
}));

export const TriangleNeonBg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "160px",
  right: "40px",
  margin: "auto",
  width: "120px",
  maxWidth: "50vw",
  objectFit: "scale-down",
  zIndex: 0,
  rotate: "-30deg",
}));

export const TriangleNeon2Bg = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "40px",
  left: "40px",
  // maxWidth: "100%",
  margin: "auto",
  width: "200px",
  maxWidth: "50vw",
  objectFit: "scale-down",
  zIndex: 0,
}));
