import { ThemeOptions, createTheme } from "@mui/material";
import "./fonts.scss";

const themeSettings: ThemeOptions = {
  palette: {
    primary: {
      main: "#f7ac97",
      light: "#ffe7e0",
    },
    secondary: {
      main: "#f7ac97",
      // light: "#ff93b9",
    },
    text: {
      primary: "#36413d",
      secondary: "#f2f2f2",
      disabled: "#d9d9d9",
    },
    background: {
      default: "#36413d",
      paper: "#d9d9d9",
    },
    info: {
      main: "#d9dadc",
    },
    warning: {
      main: "#FFD027",
    },
    success: {
      main: "#38ee78",
    },
    error: {
      main: "#ff7060",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["PathwayExtreme-Regular"].join(","),
    h1: {
      fontFamily: ["PathwayExtreme-SemiBold"].join(","),
      fontSize: "2rem",
    },
    h2: {
      fontFamily: ["PathwayExtreme-SemiBold"].join(","),
      fontSize: "1.5rem",
    },
    h3: {
      fontFamily: ["PathwayExtreme-Medium"].join(","),
      fontSize: "1.25rem",
    },
    h4: {
      fontFamily: ["PathwayExtreme-Medium"].join(","),
      fontSize: "1rem",
    },
    h5: {
      fontFamily: ["PathwayExtreme-Regular"].join(","),
      fontSize: "0.875rem",
    },
    h6: {
      fontFamily: ["PathwayExtreme-Regular"].join(","),
      fontSize: "0.6875rem",
    },
  },
};

const theme = createTheme(themeSettings);

export default theme;
