import { Fragment } from "react";
import {
  PagesContainerBox,
  TriangleNeon2Bg,
  TriangleNeonBg,
} from "./PagesContainer.styles";
import { PagesContainerProps } from "./PagesContainer.types";

/**
 * Use this container only if the pages not displayed under modal.
 * This is because the height is calculated based on the top bar
 */
export default function PagesContainer(props: PagesContainerProps) {
  const { children, isLightningBackground, pagesContainerBoxProps } = props;

  return (
    <PagesContainerBox {...pagesContainerBoxProps}>
      {/* {isLightningBackground && (
        <Fragment>
          <TriangleNeonBg
            src={process.env.PUBLIC_URL + "/assets/images/triangle-neon.png"}
            alt="lightning background"
          />
          <TriangleNeon2Bg
            src={process.env.PUBLIC_URL + "/assets/images/triangle-neon-2.png"}
            alt="lightning background"
          />
        </Fragment>
      )} */}
      {children}
    </PagesContainerBox>
  );
}
